<script setup>
import { useInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/infoInventory.store'
import { storeToRefs } from 'pinia'
import { ref, onMounted, watch } from 'vue'

const infoInventoryStore = useInfoInventoryStore()
const { infoDevice, fields, infoInventory, dropdownOptionsDinamic } = storeToRefs(infoInventoryStore)
const dataFeatures = ref([])

// Watch para actualizar la tabla
watch(
  () => Object.keys(fields.value.headers).sort().toString(),
  (newFieldsKeys) => {
    // console.log('newFieldsKeys ---> ', newFieldsKeys)
    if (newFieldsKeys) {
      const { features } = fields.value
      // Enviarlo al store
      infoInventoryStore._actionGetDropdownOptionsDinamic(features)

      dataFeatures.value = [fields.value.headers]
    }
  }
)

// Watch para observar si cambia dataFeatures y setearlo en el store infoInventory
watch(
  () => dataFeatures.value,
  (newDataTableValue) => {
    // console.log('dataFeatures --> ', newDataTableValue)
    infoInventoryStore._setInfoInventory({
      ...infoInventory.value,
      features: newDataTableValue
    })
  }
)

// Agregar fila
const addRow = () => {
  if (fields.value.features) { // Verifica que fields.value no sea null
    const newRow = fields.value.features.reduce((acc, field) => {
      acc[field.nameField] = null
      // Agregar indice
      acc.index = dataFeatures.value.length + 1
      return acc
    }, {})
    dataFeatures.value.push(newRow)
  }
}

// Eliminar fila
const removeRow = (data) => {
  // Si solo hay una fila, no se puede eliminar
  if (dataFeatures.value.length > 1) {
    const index = dataFeatures.value.indexOf(data)
    if (index > -1) {
      dataFeatures.value.splice(index, 1)
      // Reordenar las líneas
      dataFeatures.value.forEach((item, index) => {
        item.index = index + 1
      })
    }
  }
}

// Hook
onMounted(async () => {
  console.log('sectionFieldsDevice mounted', dataFeatures.value)
})

</script>

<template>
  <div class="intro-y box mt-2">
    <!-- Titulo Card -->
    <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
      <h2 class="font-medium text-sm mr-auto">Caracteristicas</h2>
    </div>
    <!-- Body Card -->
    <div class="p-4">
      <!-- Imagen -->
      <div v-if="!infoDevice.id" class="flex flex-col justify-center items-center opacity-50">
        <span class="text-sm font-bold text-yellow-700 my-4">Selecciona un tipo de dispositivo para ver las caracteristicas disponibles a ingresar.</span>
        <svg xmlns="http://www.w3.org/2000/svg" style="width: 250px; height: auto;" viewBox="0 0 64 64" x="0px" y="0px">
          <g>
            <path d="M20,59a1,1,0,0,0,1,1H43a1,1,0,0,0,1-1V48H20Z"/>
            <path d="M43,40H21a1,1,0,0,0-1,1v5H44V41A1,1,0,0,0,43,40Zm-9,4H32V42h2Zm4,0H36V42h2Zm4,0H40V42h2Z"/>
            <path d="M17,4H5A1,1,0,0,0,4,5V21a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V5A1,1,0,0,0,17,4ZM12,20H10V18h2Z"/>
            <circle cx="13" cy="50" r="3"/>
            <path d="M48,22h8a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1,3.009,3.009,0,0,1-3,3H51a3.009,3.009,0,0,1-3-3,1,1,0,0,0-1,1V21A1,1,0,0,0,48,22Z"/>
            <path d="M15.71,44.49a4.26,4.26,0,0,0-.7-.28,1.019,1.019,0,0,1-.69-.83L14.02,41H11.98l-.3,2.38a1.019,1.019,0,0,1-.69.83,4.26,4.26,0,0,0-.7.28.982.982,0,0,1-1.04-.11L7.36,42.91,5.91,44.36l1.47,1.89a.982.982,0,0,1,.11,1.04,4.26,4.26,0,0,0-.28.7,1.019,1.019,0,0,1-.83.69L4,48.98v2.04l2.38.3a1.019,1.019,0,0,1,.83.69,4.26,4.26,0,0,0,.28.7.982.982,0,0,1-.11,1.04L5.91,55.64l1.45,1.45,1.89-1.47a.982.982,0,0,1,1.04-.11,4.26,4.26,0,0,0,.7.28,1.019,1.019,0,0,1,.69.83l.3,2.38h2.04l.3-2.38a1.019,1.019,0,0,1,.69-.83,4.26,4.26,0,0,0,.7-.28.98.98,0,0,1,1.04.11l1.25.97V50a5,5,0,1,1-5-5,5.006,5.006,0,0,1,5,5V43.41l-1.25.97A.98.98,0,0,1,15.71,44.49Z"/>
            <path d="M51,5h2a1,1,0,0,0,1-1H50A1,1,0,0,0,51,5Z"/>
            <path d="M56.79,52.01a1.019,1.019,0,0,1,.83-.69l2.38-.3V48.98l-2.38-.3a1.019,1.019,0,0,1-.83-.69,4.26,4.26,0,0,0-.28-.7.982.982,0,0,1,.11-1.04l1.47-1.89-1.45-1.45-1.89,1.47a.98.98,0,0,1-1.04.11,4.26,4.26,0,0,0-.7-.28,1.019,1.019,0,0,1-.69-.83L52.02,41H49.98l-.3,2.38a1.019,1.019,0,0,1-.69.83,4.26,4.26,0,0,0-.7.28.982.982,0,0,1-1.04-.11L46,43.41V50a5,5,0,1,1,5,5,5.006,5.006,0,0,1-5-5v6.59l1.25-.97a.982.982,0,0,1,1.04-.11,4.26,4.26,0,0,0,.7.28,1.019,1.019,0,0,1,.69.83l.3,2.38h2.04l.3-2.38a1.019,1.019,0,0,1,.69-.83,4.26,4.26,0,0,0,.7-.28.98.98,0,0,1,1.04.11l1.89,1.47,1.45-1.45-1.47-1.89a.982.982,0,0,1-.11-1.04A4.26,4.26,0,0,0,56.79,52.01Z"/>
            <circle cx="51" cy="50" r="3"/>
            <path d="M28,35h2V13a1,1,0,0,0-1-1H22v2h6Z"/>
            <path d="M36,14h6V12H35a1,1,0,0,0-1,1V35h2Z"/>
          </g>
        </svg>
      </div>
      <div v-else-if="fields.features.length === 0" class="flex flex-col justify-center items-center opacity-50">
        <span class="text-sm font-bold text-red-700 my-4">El tipo de dispositivo <u>{{ infoDevice.devicename }}</u> no tiene caracteristicas o campos adicionales por ingresar.</span>
        <svg xmlns="http://www.w3.org/2000/svg" style="width: 250px; height: auto;"
             xmlns:svg="http://www.w3.org/2000/svg" viewBox="0 0 8 8" version="1.1" x="0px" y="0px">
          <g transform="translate(-53.65643,-168.17762)">
            <path style="color:#000000;"
                  d="m 57.361328,169.23633 c -1.458112,0 -2.646484,1.18642 -2.646484,2.64453 0,1.45811 1.188372,2.64648 2.646484,2.64648 1.458113,0 2.644531,-1.18837 2.644531,-2.64648 0,-1.45811 -1.186418,-2.64453 -2.644531,-2.64453 z m 0,0.52929 c 1.17213,10e-6 2.115234,0.94311 2.115234,2.11524 0,1.17213 -0.943104,2.11719 -2.115234,2.11719 -1.17213,0 -2.117187,-0.94506 -2.117187,-2.11719 0,-1.17213 0.945057,-2.11523 2.117187,-2.11524 z"
                  fill="#000000" stroke-linecap="round" stroke-linejoin="round"/>
            <path style="font-variation-settings:'wght' 700;"
                  d="m 57.36073,170.0297 a 1.8520757,1.8520757 0 0 0 -1.85208,1.85209 1.8520757,1.8520757 0 0 0 1.85208,1.85208 1.8520757,1.8520757 0 0 0 1.85208,-1.85208 1.8520757,1.8520757 0 0 0 -1.85208,-1.85209 z m -0.0214,0.62219 c 0.47332,-1e-5 0.86012,0.38887 0.86012,0.86294 0,0.40284 -0.27924,0.74505 -0.65353,0.83882 v 0.007 a 0.20578716,0.20578716 0 0 1 -0.20659,0.20659 0.20578716,0.20578716 0 0 1 -0.20498,-0.20659 v -0.18847 a 0.20578716,0.20578716 0 0 1 0.003,-0.0354 0.20578716,0.20578716 0 0 1 0.009,-0.0346 0.20578716,0.20578716 0 0 1 0.0153,-0.0322 0.20578716,0.20578716 0 0 1 0.0459,-0.0547 0.20578716,0.20578716 0 0 1 0.0293,-0.0205 0.20578716,0.20578716 0 0 1 0.0321,-0.0153 0.20578716,0.20578716 0 0 1 0.0345,-0.009 0.20578716,0.20578716 0 0 1 0.0353,-0.003 c 0.24968,0 0.44976,-0.19973 0.44976,-0.45257 0,-0.25284 -0.20008,-0.45258 -0.44976,-0.45257 -0.24964,0 -0.44814,0.19973 -0.44814,0.45257 a 0.20578716,0.20578716 0 0 1 -0.20659,0.20659 0.20578716,0.20578716 0 0 1 -0.20498,-0.20659 c 0,-0.47407 0.38642,-0.86294 0.85971,-0.86294 z m 4e-4,2.05224 a 0.20341731,0.20452558 0 0 1 0.20338,0.20458 0.20341731,0.20452558 0 0 1 -0.20338,0.20458 0.20341731,0.20452558 0 0 1 -0.20338,-0.20458 0.20341731,0.20452558 0 0 1 0.20338,-0.20458 z"
                  fill="#000000" stroke="none"/>
            <path style="color:#000000;"
                  d="m 59.117187,173.07617 a 0.2645835,0.2645835 0 0 0 0,0.375 l 1.291016,1.29102 c 0.118504,0.11851 0.127112,0.18326 0.126953,0.21289 -1.59e-4,0.0296 -0.01595,0.053 -0.0332,0.0703 -0.01458,0.0146 -0.03929,0.0309 -0.07031,0.0312 -0.03102,3.3e-4 -0.09517,-0.007 -0.212891,-0.125 l -1.292969,-1.29297 a 0.2645835,0.2645835 0 0 0 -0.375,0 0.2645835,0.2645835 0 0 0 0,0.375 l 1.292969,1.29297 c 0.188282,0.18832 0.399429,0.28134 0.59375,0.2793 0.194321,-0.002 0.344899,-0.0909 0.439453,-0.18555 0.09492,-0.095 0.186454,-0.24881 0.1875,-0.44336 0.001,-0.19455 -0.09571,-0.40234 -0.283203,-0.58984 l -1.289063,-1.29102 a 0.2645835,0.2645835 0 0 0 -0.375,0 z"
                  fill="#000000" stroke-linecap="round" stroke-linejoin="round"/>
            <path style=""
                  d="m 59.29898,173.25897 1.2959,1.29595 c 0.306,0.30601 0.20668,0.54523 0.0945,0.65748 -0.10913,0.10918 -0.35225,0.21236 -0.65825,-0.0937 l -1.29593,-1.296"
                  fill="#000000" stroke="none"/>
          </g>
        </svg>
      </div>
      <!-- Campos -->
      <div v-else>
<!--        <pre style="font-size: 11px; line-height: 1"><code>{{ JSON.stringify(fields, null, '\t') }}</code></pre>-->
        <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-4">
          <div class="col-span-1 sm:col-span-12 md:col-span-12">
            <!-- Tabla -->
            <DataTable
              ref="dt"
              :value="dataFeatures"
              tableStyle="min-width: 50rem"
              class="p-datatable-sm text-xs"
              stripedRows
              dataKey="id"
              showGridlines
            >
              <template #empty> No hay datos para mostrar. </template>
              <template #loading> Cargando la información, por favor espere... </template>
              <Column
                headerStyle="width: 1rem; text-align: center"
                bodyStyle="text-align: center; overflow: visible"
              >
                <template #body="{ data }">
                  <Button
                    type="button"
                    severity="danger"
                    class="p-button-xs"
                    icon="pi pi-trash"
                    v-tippy="{ content: 'Eliminar fila' }"
                    @click="removeRow(data)"
                  />
                </template>
              </Column>
              <!-- Recorrer los campos para mostrarlos como columnas -->
              <Column
                v-for="(field) in fields.features"
                :key="field.id"
              >
                <template #header>
                  <div class="flex flex-col">
                    <span class="font-bold text-sm">{{ field.showName }}
                      <Badge
                        v-if="field.isRequired"
                        :value="`Requerido`"
                        style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                        class="bg-red-100 text-red-900 capitalize cursor-pointer font-bold"
                      />
                      <Badge
                        v-if="field.isUnique"
                        :value="`Único`"
                        style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                        class="bg-red-100 text-red-900 capitalize cursor-pointer font-bold"
                      />
                    </span>
                    <span class="font-normal text-xxs">{{ field.description }}</span>
                  </div>
                </template>
                <template #body="{ data }">
<!--                  <pre style="font-size: 11px; line-height: 1"><code>{{ JSON.stringify(field, null, '\t') }}</code></pre>-->
<!--                  <pre style="font-size: 11px; line-height: 1"><code>{{ JSON.stringify(dropdownOptionsDinamic, null, '\t') }}</code></pre>-->
                  <InputText
                    v-if="field.dataType === 'text'"
                    type="text"
                    v-model="data[field.nameField]"
                    size="small"
                    class="w-full"
                    :pt="{
                      root: { style: 'height: 2rem' },
                      input: { style: 'font-size: 0.875rem' },
                      panel: { style: 'font-size: 0.875rem' }
                    }"
                  />
                  <InputNumber
                    v-else-if="field.dataType === 'number'"
                    v-model="data[field.nameField]"
                    class="w-full"
                    :mode="field.numberFormat === 'currency' ? 'currency' : 'decimal'"
                    :currency="`COP`"
                    :locale="`es-CO`"
                    :useGrouping="field.numberFormat !== 'integer'"
                    :maxFractionDigits="field.numberFormat === 'decimal' ? 1 : 0"
                    :suffix="field.numberFormat === 'percent' ? '%' : ''"
                    :pt="{
                      root:{ style: 'height: 2rem' },
                      input: { style: 'width:100%; font-size: 0.875rem;' }
                    }"
                  />
                  <Textarea
                    v-else-if="field.dataType === 'textarea'"
                    v-model="data[field.nameField]"
                    rows="3"
                    cols="20"
                    class="w-full"
                  />
                  <InputText
                    v-else-if="field.dataType === 'date'"
                    type="date"
                    v-model="data[field.nameField]"
                    size="small"
                    class="w-full"
                    :pt="{
                      root: { style: 'height: 2rem' },
                      input: { style: 'font-size: 0.875rem' },
                      panel: { style: 'font-size: 0.875rem' }
                    }"
                  />
                  <InputText
                    v-else-if="field.dataType === 'email'"
                    type="email"
                    v-model="data[field.nameField]"
                    size="small"
                    class="w-full"
                    placeholder="example@domain.com"
                    :pt="{
                      root: { style: 'height: 2rem' },
                      input: { style: 'font-size: 0.875rem' },
                      panel: { style: 'font-size: 0.875rem' }
                    }"
                  />
                  <Dropdown
                    v-else-if="field.dataType === 'select'"
                    v-model="data[field.nameField]"
                    :options="dropdownOptionsDinamic[field.nameField]"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Seleccione una opción"
                    :filter="true"
                    scrollHeight="300px"
                    filterPlaceholder="Buscar por nombre"
                    autoFilterFocus
                    class="text-xs w-full"
                    inputClass="w-full"
                    :pt="{
                      root: { style: 'height: 2rem' },
                      input: { style: 'font-size: 0.875rem' },
                      panel: { style: 'font-size: 0.875rem' }
                    }"
                  />
                  <InputSwitch
                    v-else-if="field.dataType === 'boolean'"
                    v-model="data[field.nameField]"
                  />
                  <div v-else-if="field.dataType === 'checkbox'">
                    <div v-for="category of dropdownOptionsDinamic[field.nameField]" :key="category.value" class="flex align-items-center mb-2 gap-2">
                      <Checkbox v-model="data[field.nameField]" :inputId="category.value" :name="field.nameField" :value="category.value" />
                      <label :for="category.value">{{ category.label }}</label>
                    </div>
                  </div>

                  <div v-else-if="field.dataType === 'radio'">
                    <div v-for="category of dropdownOptionsDinamic[field.nameField]" :key="category.value" class="flex align-items-center mb-2 gap-2">
                      <RadioButton v-model="data[field.nameField]" :inputId="category.value" :name="field.nameField" :value="category.value" />
                      <label :for="category.value">{{ category.label }}</label>
                    </div>
                  </div>

                </template>
              </Column>
            </DataTable>

            <div class="grid grid-cols-6 gap-4 mt-4">
              <Button
                label="Agregar Fila"
                icon="pi pi-plus"
                size="small"
                severity="primary"
                class="col-span-6 sm:col-span-3 md:col-span-3 lg:col-span-2 xl:col-span-1"
                @click="addRow"
                v-tippy="{ content: 'Agregar fila' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
