<script setup>
import { computed, onMounted, ref, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import DeviceService from '@/apps/dailyTech/inventario-ti/services/devices.service'
import BrandService from '@/apps/dailyTech/inventario-ti/services/brands.service'
import ModelsService from '@/apps/dailyTech/inventario-ti/services/models.service'
import LocationsService from '@/apps/dailyTech/inventario-ti/services/locations.service'
import OwnersService from '@/apps/dailyTech/inventario-ti/services/owners.service'
import StatusService from '@/apps/dailyTech/inventario-ti/services/status.service'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import Icon from '@/components/Icon/index.vue'
import { useToast } from 'primevue/usetoast'
import UploadFilePond from '@/apps/dailyTech/inventario-ti/views/components/uploadFilePond/index.vue'
import { useStore } from '@/store'
import { useInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/infoInventory.store'
import { useViewEditInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/viewEditInfoInventory.store'
import SectionFieldsDevice from '@/apps/dailyTech/inventario-ti/views/form/components/sectionFieldsDevice.vue'
import { storeToRefs } from 'pinia'
import Swal from 'sweetalert2'

const infoInventoryStore = useInfoInventoryStore()
const viewEditInfoInventoryStore = useViewEditInfoInventoryStore()
const { isValidForm, fields, infoInventory } = storeToRefs(infoInventoryStore)
const store = useStore()
const route = useRoute()
const router = useRouter()
const toast = useToast()
const listTypeDevices = ref([])
const _DeviceService = ref(new DeviceService())
const _BrandService = ref(new BrandService())
const _ModelsService = ref(new ModelsService())
const _LocationsService = ref(new LocationsService())
const _OwnersService = ref(new OwnersService())
const _StatusService = ref(new StatusService())
const _InventoryService = ref(new InventoryService())
const listBrands = ref([])
const opBrand = ref()
const objNewBrand = reactive({
  brandname: null,
  status: true
})
const brandSelected = reactive({
  id: null,
  name: null
})
const listModels = ref([])
const opModel = ref()
const objNewModel = reactive({
  modelname: null,
  idbrand: null,
  image: null,
  status: true
})
const modelSelected = reactive({
  modelname: null,
  idbrand: null,
  image: null,
  status: true
})
const existImgModel = ref(false)
const listLocations = ref([])
const listStatus = ref([])
const listOwners = ref([])
// Temporary model
const registerInventory = reactive({
  typeDevice: null,
  brand: null,
  model: null,
  modelName: null,
  location: null,
  status: null,
  owner: null,
  photo: null
})
const formSubmitted = ref(false)
const messageError = ref([])
const photoModel = ref(null)

// Obtener los tipos de dispositivos
const getListDevices = async () => {
  await _DeviceService.value.getDevices()
    .then(response => {
      listTypeDevices.value = response.data
    })
    .catch(error => {
      console.log('Error al obtener los devices: ', error)
    })
}

// Funcion cuando cambia el tipo de dispositivo
const changeTypeDevice = async (event) => {
  // Obtener la info del device con el event.value
  const infoDevice = listTypeDevices.value.find(device => device.id === event.value)

  await infoInventoryStore._actionFieldsByDevice(infoDevice)

  registerInventory.brand = null
  registerInventory.model = null
}

// Obtener las marcas
const getListBrands = async () => {
  await _BrandService.value.getBrands()
    .then(response => {
      listBrands.value = response.data
    })
    .catch(error => {
      console.log('Error al obtener las marcas: ', error)
    })
}

// Cuando cambia la marca seleccionada se obtienen los modelos
watch(() => registerInventory.brand, async (newValue) => {
  if (!newValue) return
  await getModelsByBrand(newValue)
  console.log('Marca seleccionada: ', newValue)
  brandSelected.id = newValue
  brandSelected.name = listBrands.value.find(brand => brand.id === newValue).brandname

  // Limpiar valores de modelSelected a null con un for
  for (const key in modelSelected) {
    modelSelected[key] = null
  }

  // Limpiar registerInventory.model
  registerInventory.model = null
})

// Cuando cambia el modelo seleccionado se actualiza el objeto modelSelected
watch(() => registerInventory.model, (newValue) => {
  if (!newValue) return
  modelSelected.modelname = listModels.value.find(model => model.id === newValue).modelname
  modelSelected.idbrand = listModels.value.find(model => model.id === newValue).idbrand
  modelSelected.image = listModels.value.find(model => model.id === newValue).image
  modelSelected.status = listModels.value.find(model => model.id === newValue).status

  registerInventory.photo = modelSelected.image
  registerInventory.modelName = modelSelected.modelname

  // Verificar si existe imagen
  existImgModel.value = !!modelSelected.image
})

// Watch para estar pendiente de cambios en registerInventory y setearlo en el store
watch(() => registerInventory, (newValue) => {
  infoInventoryStore._setInfoInventory({
    ...infoInventoryStore._getInfoInventory,
    general: newValue
  })
}, { deep: true })

// Guardar nueva marca
const createBrand = async () => {
  if (!objNewBrand.brandname) return

  await _BrandService.value.createBrand(objNewBrand)
    .then(response => {
      const { data } = response
      if (!data.success) {
        return
      }

      listBrands.value.push(data.info) // Agregar la marca creada
      listBrands.value.sort((a, b) => a.brandname.localeCompare(b.brandname)) // Ordenar alfabéticamente
      registerInventory.brand = data.info.id // Seleccionar la marca creada
      registerInventory.model = null

      clearValues() // Limpiar valores de objNewBrand y objNewModel

      opBrand.value.hide()
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Marca creada exitosamente', life: 3000 })
    })
    .catch(error => {
      toast.add({ severity: 'error', summary: 'Falló', detail: 'Error al crear la marca', life: 3000 })
      console.log('Error al crear la marca: ', error)
    })
}

// Abrir OverlayPanel para añadir nueva marca
const toggleNewBrand = (event) => {
  opBrand.value.toggle(event)
}

// Obtener los modelos de la marca seleccionada
const getModelsByBrand = async (idbrand) => {
  await _ModelsService.value.getModels({ idbrand })
    .then(response => {
      listModels.value = response.data
    })
    .catch(error => {
      console.log('Error al obtener los modelos: ', error)
    })
}

// Guardar nuevo modelo
const createModel = async () => {
  if (!objNewModel.modelname) return

  objNewModel.idbrand = registerInventory.brand

  const formData = new FormData()
  formData.append('modelname', objNewModel.modelname)
  formData.append('idbrand', objNewModel.idbrand)
  formData.append('status', objNewModel.status)
  formData.append('image', objNewModel.image)

  await _ModelsService.value.createModel(formData)
    .then(response => {
      const { data } = response
      if (!data.success) {
        return
      }

      listModels.value.push(data.info) // Agregar el modelo creado
      listModels.value.sort((a, b) => a.modelname.localeCompare(b.modelname)) // Ordenar alfabéticamente
      registerInventory.model = data.info.id // Seleccionar el modelo creado
      clearValues() // Limpiar valores de objNewBrand y objNewModel
      opModel.value.hide()
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Modelo creado exitosamente', life: 3000 })
    })
    .catch(error => {
      toast.add({ severity: 'error', summary: 'Falló', detail: 'Error al crear el modelo', life: 3000 })
      console.log('Error al crear el modelo: ', error)
    })
}

// Abrir OverlayPanel para añadir nuevo modelo
const toggleNewModel = (event) => {
  opModel.value.toggle(event)
}

// Inicializar filepond usado para la imagen del modelo nuevo
const onInitFilePondNew = () => {
  console.log('Inicializando filepond new...')
  existImgModel.value = false
}
// Capturar archivo del modelo nuevo
const onCaptureFileNew = (files) => {
  console.log('Capturando archivo new: ', files)
  objNewModel.image = files
  existImgModel.value = false
  if (!modelSelected.image) {
    photoModel.value = files
  } else {
    photoModel.value = null
  }
}
// Remover archivo del modelo nuevo
const onRemoveFileNew = () => {
  console.log('Removiendo archivo new...')
  objNewModel.image = null
  if (modelSelected.image) {
    existImgModel.value = true
  }

  photoModel.value = null
}

// Inicializar filepond usado para la imagen del modelo existente
const onInitFilePondExist = () => {
  console.log('Inicializando filepond exist...')
  existImgModel.value = false
}
// Capturar archivo del modelo existente
const onCaptureFileExist = (files) => {
  console.log('Capturando archivo exist: ', files)
  objNewModel.image = files
  existImgModel.value = false
  if (!modelSelected.image) {
    photoModel.value = files
  } else {
    photoModel.value = null
  }
}
// Remover archivo del modelo existente
const onRemoveFileExist = () => {
  console.log('Removiendo archivo exist...')
  objNewModel.image = null
  if (modelSelected.image) {
    existImgModel.value = true
  }

  photoModel.value = null
}

// Obtener la imagen del modelo
const getImageModel = (image) => {
  const token = store.getters['auth/getToken']
  const obj = { image, token }
  return _ModelsService.value.getImageModel(obj)
}

// Limpiar valores de objNewBrand y objNewModel
const clearValues = () => {
  objNewBrand.brandname = null
  objNewModel.modelname = null
  objNewModel.idbrand = null
  objNewModel.image = null
}

// Obtener las ubicaciones
const getListLocations = async () => {
  await _LocationsService.value.getLocations()
    .then(response => {
      listLocations.value = response.data
    })
    .catch(error => {
      console.log('Error al obtener las ubicaciones: ', error)
    })
}

// Obtener los dueños
const getListOwners = async () => {
  await _OwnersService.value.getOwners()
    .then(response => {
      listOwners.value = response.data
    })
    .catch(error => {
      console.log('Error al obtener los dueños: ', error)
    })
}

// Obtener los estados
const getListStatus = async () => {
  await _StatusService.value.getStatus()
    .then(response => {
      listStatus.value = response.data
    })
    .catch(error => {
      console.log('Error al obtener los estados: ', error)
    })
}

// Validar campos generales
const validateFields = () => {
  const validations = {
    typeDevice: 'El campo <span class="text-red-600 font-bold">Tipo de dispositivo</span> es requerido',
    brand: 'El campo <span class="text-red-600 font-bold">Marca</span> es requerido',
    model: 'El campo <span class="text-red-600 font-bold">Modelo</span> es requerido',
    location: 'El campo <span class="text-red-600 font-bold">Ubicación</span> es requerido',
    status: 'El campo <span class="text-red-600 font-bold">Estado</span> es requerido',
    owner: 'El campo <span class="text-red-600 font-bold">Dueño</span> es requerido'
  }

  let isFormValid = true

  for (const field in validations) {
    if (!registerInventory[field]) {
      messageError.value.push(validations[field])
      isFormValid = false
    }
  }

  return isFormValid
}

// Validar campos de las características
const validateFeatures = () => {
  if (Object.keys(infoInventory.value).length !== 0) {
    if (infoInventory.value.features && infoInventory.value.features.length > 0) {
      let isFormValid = true

      for (const item of infoInventory.value.features) {
        for (const key of Object.keys(item)) {
          const field = fields.value.features.find((field) => field.nameField === key)
          if (field && field.isRequired && !item[key]) {
            messageError.value.push(`El campo <span class="text-red-600 font-bold">${field.showName}</span> es requerido`)
            isFormValid = false
          }

          // Se valida dependiendo el tipo de campo
          if (key === 'email') {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
            if (!emailRegex.test(item[key])) {
              // Obtener el índice para decir cuál es el campo que no cumple con la validación
              const index = infoInventory.value.features.findIndex((feature) => feature[key] === item[key])
              messageError.value.push(`El campo <span class="text-red-600 font-bold">${field.showName}</span> de la fila ${index + 1} no es un correo válido`)
              isFormValid = false
            }
          }
        }
      }

      return isFormValid
    }
  }

  return true
}

// Enviar formulario
const onSubmit = () => {
  isValidForm.value = true
  formSubmitted.value = true
  messageError.value = []

  // Realizar validaciones
  if (!validateFields() || !validateFeatures()) {
    isValidForm.value = false
    return
  }

  Swal.fire({
    icon: 'info',
    title: '¿Esta seguro/a?',
    text: '¿Desea guardar el registro de inventario?',
    showConfirmButton: true,
    confirmButtonColor: '#24963E',
    confirmButtonText: 'Si, Guardar!',
    showCancelButton: true,
    cancelButtonColor: '#d33',
    cancelButtonText: 'No, cancelar!'
  }).then(async (resp) => {
    if (resp.isConfirmed) {
      try {
        const formData = new FormData()

        formData.append('infoInventory', JSON.stringify(infoInventory.value))
        formData.append('image', photoModel.value)

        const response = await _InventoryService.value.createInventory(formData)
        if (response.status === 200) {
          // Agregar un sweet alert de éxito y dar la opción de ir a la lista de inventario o ingresar otro registro
          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'Registro de inventario creado exitosamente',
            showConfirmButton: true,
            confirmButtonColor: '#24963E',
            confirmButtonText: 'Nuevo registro!',
            showCancelButton: true,
            cancelButtonColor: '#337ddd',
            cancelButtonText: 'Ir al listado!'
          }).then((result) => {
            if (result.isConfirmed) {
              // Limpiar el store
              infoInventoryStore._clearStoreInventory()
              window.location.reload()
            }

            if (result.isDismissed) {
              // Limpiar el store
              infoInventoryStore._clearStoreInventory()
              router.push({ name: 'daily-tech.inventario-ti.listado' })
            }
          })
        }
      } catch (error) {
        console.log('Error al crear el registro de inventario: ', error.response)
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          html: `Hubo un error al crear el registro de inventario <br> <span style="font-size: 13px;">${error.response.data.message ?? ''}</span> <br> <span style="font-size: 13px;">${error.response.data.details ?? ''}</span>`
        })
      }
    }
  })
}

// Validar si es edición
const isEdit = computed(() => {
  return !!route.params.id
})

// Hook onMounted
onMounted(async () => {
  console.log('OnMounted Form...')

  await getListDevices() // Obtener los tipos de dispositivos
  await getListBrands() // Obtener las marcas
  await getListLocations() // Obtener las ubicaciones
  await getListOwners() // Obtener los dueños
  await getListStatus() // Obtener los estados

  // Limpiar viewEditInfoInventoryStore
  viewEditInfoInventoryStore._actionClearViewEditInfoInventory()
  // Limpiar el store de infoInventory
  infoInventoryStore._clearStoreInventory()
})
</script>

<template>
  <Toast />
  <Toolbar class="box mt-4 p-2">
    <template #end>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid grid-cols-12 gap-6 mt-4">
    <div class="intro-y col-span-12 lg:col-span-12">
      <!-- Titulo -->
      <div class="flex flex-col sm:flex-row items-center py-2">
        <h2 class="font-medium text-lg mr-auto" :class="isEdit ? 'text-yellow-600' : 'text-green-800'">{{ isEdit ? 'Editar Registro de Inventario' : 'Nuevo Registro de Inventario' }}</h2>
      </div>

      <!-- Card Informacion general -->
      <div class="intro-y box">
        <!-- Titulo Card -->
        <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-sm mr-auto">General</h2>
          <Badge
            v-if="!isValidForm && formSubmitted"
            :value="`Visualizar mensajes de error de validación`"
            style="height: 1.2rem; font-size: 0.7rem; line-height: 1.2rem;"
            class="bg-red-100 text-red-900 capitalize cursor-pointer font-bold"
            v-tippy="{ content: messageError.join('<br>') }"
          />
        </div>
        <!-- Body Card -->
        <div class="p-4">
          <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div class="sm:col-span-6 md:col-span-6 lg:col-span-4">
              <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <!-- Tipo de dispositivo -->
                <div class="sm:col-span-6">
                  <label for="typeDevice" class="block text-xs font-medium text-gray-700 mb-2">
                    Tipo de dispositivo:
                    <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                  </label>
                  <Dropdown
                    id="typeDevice"
                    v-model="registerInventory.typeDevice"
                    @change="changeTypeDevice"
                    :options="listTypeDevices"
                    optionLabel="devicename"
                    optionValue="id"
                    placeholder="Seleccione una opción"
                    :filter="true"
                    scrollHeight="300px"
                    filterPlaceholder="Buscar por nombre"
                    autoFilterFocus
                    class="w-full"
                    :class="{ 'border-red-600': formSubmitted && !registerInventory.typeDevice }"
                    :pt="{
                      panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                      item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                    }"
                  >
                    <template #option="{ option }">
                      <div class="flex items-center">
                        <Icon :icon="option.icon" class="text-3xl mr-2 opacity-40" />
                        <div>
                          <div class="font-medium">{{ option.devicename }}</div>
                        </div>
                      </div>
                    </template>
                  </Dropdown>
                </div>

                <!-- Marca -->
                <div class="sm:col-span-6">
                  <div class="flex flex-wrap justify-between">
                    <label for="brand" class="block text-xs font-medium text-gray-700 mb-2">
                      Marca:
                      <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                    </label>
                    <!-- Añadir nueva marca -->
                    <div
                      class="flex flex-wrap items-start cursor-pointer text-xs text-blue-600 hover:underline"
                      @click="toggleNewBrand($event)"
                    >
                      <Icon icon="mdi:plus-circle" class="text-base mr-1" />
                      <span>Añadir nueva marca</span>
                    </div>
                    <OverlayPanel
                      ref="opBrand"
                      :pt="{
                    root: {
                      style: 'width: 300px; margin-top: 0'
                    },
                    content: {
                      style: 'padding: 1rem'
                    }
                  }"
                    >
                      <div class="flex flex-col">
                        <label for="nameBrand" class="block text-xs font-medium text-gray-700 mb-2">
                          Nombre:
                          <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                        </label>
                        <InputText
                          id="nameBrand"
                          placeholder="Ingresa el nombre de la marca"
                          v-model="objNewBrand.brandname"
                        />
                        <!-- Mensaje de error -->
                        <div class="text-xxs text-red-500" v-if="!objNewBrand.brandname">El campo es requerido.</div>
                        <Button
                          label="Guardar"
                          icon="pi pi-save"
                          severity="success"
                          size="small"
                          class="mt-2"
                          @click="createBrand"
                        />
                      </div>
                    </OverlayPanel>
                  </div>
                  <Dropdown
                    id="brand"
                    v-model="registerInventory.brand"
                    :options="listBrands"
                    optionLabel="brandname"
                    optionValue="id"
                    placeholder="Seleccione una opción"
                    :disabled="!registerInventory.typeDevice"
                    :filter="true"
                    scrollHeight="300px"
                    filterPlaceholder="Buscar por nombre"
                    autoFilterFocus
                    class="w-full"
                    :class="{ 'border-red-600': formSubmitted && !registerInventory.brand }"
                    :pt="{
                  panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                  item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                }"
                  />
                </div>

                <!-- Modelo -->
                <div class="sm:col-span-6">
                  <div class="flex flex-wrap justify-between">
                    <label for="model" class="block text-xs font-medium text-gray-700 mb-2">
                      Modelo:
                      <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                    </label>
                    <!-- Añadir nuevo modelo -->
                    <div
                      v-if="registerInventory.brand"
                      class="flex flex-wrap items-start cursor-pointer text-xs text-blue-600 hover:underline"
                      @click="toggleNewModel($event)"
                    >
                      <Icon icon="mdi:plus-circle" class="text-base mr-1" />
                      <span>Añadir nuevo modelo</span>
                    </div>
                    <OverlayPanel
                      ref="opModel"
                      :pt="{
                        root: {
                          style: 'width: 300px; margin-top: 0'
                        },
                        content: {
                          style: 'padding: 1rem'
                        }
                      }"
                    >
                      <div class="flex flex-col">
                        <div class="flex flex-col">
                          <label for="nameModel" class="block text-xs font-medium text-gray-700 mb-2">
                            Nombre:
                            <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                          </label>
                          <InputText
                            id="nameModel"
                            placeholder="Ingresa el nombre del modelo"
                            v-model="objNewModel.modelname"
                          />
                          <!-- Mensaje de error -->
                          <div class="text-xxs text-red-500" v-if="!objNewModel.modelname">El campo es requerido.</div>
                        </div>
                        <div class="flex flex-col mt-2">
                          <label for="image" class="block text-xs font-medium text-gray-700 mb-2">Imagen:</label>
                          <UploadFilePond
                            :typeFilePond="'newModel'"
                            @initFilePondNew="onInitFilePondNew"
                            @captureFileNew="onCaptureFileNew"
                            @removeFileNew="onRemoveFileNew"
                          />
                        </div>
                        <!-- Mostrar la marca seleccionada -->
                        <div class="text-xs font-medium text-gray-700 mb-2">Marca: {{ brandSelected.name }}</div>
                        <Button
                          label="Guardar"
                          icon="pi pi-save"
                          severity="success"
                          size="small"
                          class="mt-2"
                          @click="createModel"
                        />
                      </div>
                    </OverlayPanel>
                  </div>
                  <Dropdown
                    id="model"
                    v-model="registerInventory.model"
                    :options="listModels"
                    optionLabel="modelname"
                    optionValue="id"
                    placeholder="Seleccione una opción"
                    :disabled="!registerInventory.brand"
                    :filter="true"
                    scrollHeight="300px"
                    filterPlaceholder="Buscar por nombre"
                    autoFilterFocus
                    :emptyMessage="`${registerInventory.brand ? 'No hay modelos disponibles para la marca seleccionada. Por favor, añade un nuevo modelo.' : 'Seleccione una marca para ver los modelos disponibles.'}`"
                    class="w-full"
                    :class="{ 'border-red-600': formSubmitted && !registerInventory.model }"
                    :pt="{
                      panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                      item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                    }"
                  />
                </div>

                <!-- Ubicacion -->
                <div class="sm:col-span-6">
                  <div class="flex flex-wrap justify-between">
                    <label for="location" class="block text-xs font-medium text-gray-700 mb-2">
                      Ubicación:
                      <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                    </label>
                    <Dropdown
                      id="location"
                      v-model="registerInventory.location"
                      :options="listLocations"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Seleccione una opción"
                      :disabled="!registerInventory.typeDevice"
                      :filter="true"
                      :filterFields="['name', 'depto', 'ciudad']"
                      scrollHeight="300px"
                      filterPlaceholder="Buscar por nombre"
                      autoFilterFocus
                      class="w-full"
                      :class="{ 'border-red-600': formSubmitted && !registerInventory.location }"
                      :pt="{
                        panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                        item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                      }"
                    >
                      <template #option="{ option }">
                        <div class="flex flex-col">
                          <div class="flex flex-wrap justify-between">
                            <span class="font-bold" style="font-size: 0.7rem">{{ option.name.toUpperCase() }} <span class="text-xxs font-normal">({{ option.depto }}-{{ option.ciudad }})</span></span>
                            <span class="font-bold text-gray-700" style="font-size: 0.7rem">{{ option.tipo_sede }}</span>
                          </div>
                          <div class="flex flex-wrap justify-between">
                            <span style="font-size: 0.7rem">{{ option.address }}</span>
                            <Badge
                              :value="`${option.estado}`"
                              style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                              class="whitespace-nowrap bg-gray-700 capitalize cursor-pointer font-normal"
                              :class="`${option.estado === 'INACTIVO' ? 'bg-red-700' : 'bg-green-700'}`"
                            />
                          </div>
                        </div>
                      </template>
                    </Dropdown>
                  </div>
                </div>

                <!-- Estado -->
                <div class="sm:col-span-6">
                  <div class="flex flex-wrap justify-between">
                    <label for="status" class="block text-xs font-medium text-gray-700 mb-2">
                      Estado:
                      <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                    </label>
                    <Dropdown
                      id="status"
                      v-model="registerInventory.status"
                      :options="listStatus"
                      optionLabel="statusname"
                      optionValue="id"
                      placeholder="Seleccione una opción"
                      :disabled="!registerInventory.typeDevice"
                      :filter="true"
                      scrollHeight="300px"
                      filterPlaceholder="Buscar por nombre"
                      autoFilterFocus
                      class="w-full"
                      :class="{ 'border-red-600': formSubmitted && !registerInventory.status }"
                      :pt="{
                        panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                        item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                      }"
                    >
                      <template #option="{ option }">
                        <div class="flex items-center">
                          <Icon :icon="option.icon" class="text-3xl mr-2 opacity-40" />
                          <div>
                            <div class="font-medium">{{ option.statusname }}</div>
                          </div>
                        </div>
                      </template>
                    </Dropdown>
                  </div>
                </div>

                <!-- Dueño -->
                <div class="sm:col-span-6">
                  <div class="flex flex-wrap justify-between">
                    <label for="owner" class="block text-xs font-medium text-gray-700 mb-2">
                      Dueño:
                      <span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                    </label>
                    <Dropdown
                      id="owner"
                      v-model="registerInventory.owner"
                      :options="listOwners"
                      optionLabel="ownername"
                      optionValue="id"
                      placeholder="Seleccione una opción"
                      :disabled="!registerInventory.typeDevice"
                      :filter="true"
                      scrollHeight="300px"
                      filterPlaceholder="Buscar por nombre"
                      autoFilterFocus
                      class="w-full"
                      :class="{ 'border-red-600': formSubmitted && !registerInventory.owner }"
                    />
                  </div>
                </div>

              </div>
            </div>
            <div class="sm:col-span-6 md:col-span-6 lg:col-span-2">
              <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <!-- Imagen -->
                <div class="sm:col-span-6">
                  <div class="flex flex-col w-full h-full">
                    <label for="image" class="block text-xs font-medium text-gray-700 mb-2">Imagen:</label>
                    <UploadFilePond
                      :typeFilePond="'existModel'"
                      v-if="!modelSelected.image"
                      @initFilePondExist="onInitFilePondExist"
                      @captureFileExist="onCaptureFileExist"
                      @removeFileExist="onRemoveFileExist"
                    />
                    <div
                      v-else
                      class="border border-dashed w-full h-full flex items-center justify-center"
                      style="height: 450px"
                    >
                      <Image
                        :src="getImageModel(modelSelected.image)"
                        alt="Image"
                        preview
                        :pt="{
                          root: { style: 'width: 95%; height: 95%; display: flex; justify-content: center; align-items: center' },
                          image: { style: 'width: 95%; height: 100%; object-fit: contain;' }
                        }"
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- Card Informacion Caracteristicas -->
      <SectionFieldsDevice />
    </div>
  </div>

  <Button
    label="Crear Registro"
    icon="pi pi-save"
    severity="success"
    size="small"
    class="p-button-raised p-button-success floating-button p-button-rounded"
    @click="onSubmit"
  />

</template>

<style scoped>
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 1050;
}
</style>
